.web ion-content.route-delivery {
	--ion-color-base: transparent;
}
.delivery-wrapper {
	overflow: auto;
}
.delivery-additional-restaurant-information {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.delivery-additional-restaurant-information * {
	color: var(--ion-color-primary);
}
