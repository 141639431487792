.table-items-wrapper.box-wrapper {
	padding: 0;
}
.table-items-wrapper > * {
	padding: 10px;
}
.table-items-wrapper * {
	user-select: none;
}
.table-items-wrapper > *:not(:first-child) {
	border-top: 1px solid var(--okx-box-wrapper-border);
	padding: 10px;
}
.table-items-overview-including-service-charge {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 5px;
}
.table-items-wrapper .small-text {
	opacity: 0.6;
}
