.validate-button {
  border-radius: 15px;
  padding: 0 15px;
  display: inline-block;
  text-align: center;
  user-select: none;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 12px;
  border-radius: 8px;
  overflow: hidden;
}
.validate-button .validate-status {
  color: var(--ion-color-danger-contrast);
}
.validate-button::before {
  background-color: var(--ion-color-danger);
  content: '';
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.validate-button.valid .validate-status {
  color: var(--ion-color-success-contrast);
}
.validate-button.valid::before {
  background-color: var(--ion-color-success);
}

.validate-button .normal-text {
  z-index: 2;
}
.validate-button ion-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.route-loyalty .validate-button.valid{
	display: none;
}
.route-loyalty .validate-button.valid + *{
	margin-top: 0;
}