:root {
  --drawer-menu-marker-height: 40px;
}

.menu-enabled .list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.drawer-menu ion-content,
.drawer-menu ion-footer {
  padding-left: var(--ion-padding);
  padding-right: var(--ion-padding);
}

.drawer-menu ion-header,
.drawer-menu ion-footer {
  background-color: var(--okx-menu-background);
}

.drawer-menu ion-footer {
  background-image: none;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  border-top: 1px solid var(--okx-box-wrapper-border);
}
.drawer-menu ion-footer .footer-link {
  --inner-border-width: 0;
  border-bottom: none;
}
.footer-link.nav-item {
  --detail-icon-color: transparent !important;
}
.drawer-menu ion-footer::before {
  background-image: none;
}

.drawer-menu .nav-item {
  --padding-start: 0;
  --min-height: 24px;
}
ion-header ion-toolbar:first-of-type {
  margin-top: var(--ion-safe-area-top, 0);
  padding-top: 0px;
}

.drawer-menu ion-header ion-toolbar:last-child {
  --border-width: 0 0 0;
  --background: transparent;
}

.logo-5l {
  position: absolute;
  right: var(--ion-padding);
  bottom: 8px;
}

ion-split-pane {
  --side-max-width: var(--okx-drawer-width);
  --side-min-width: var(--okx-drawer-width);
  --side-width: var(--okx-drawer-width);
  --border: 0 none;
}

.web ion-menu {
  --max-width: var(--okx-drawer-width);
  --background: var(--ion-color-white);
}

.drawer-menu {
  --border: 0 none;
  --width: 95%;
}

.drawer-menu ion-content {
  --background: var(--okx-menu-background);
}

.logo-5l > div {
  width: 100px;
  padding-top: 45%;
  background: url(../../assets/images/5loyalty-black.svg) transparent no-repeat 50% 50%;
  background-size: 85% auto;
}

ion-footer > ion-label[hidden] {
  display: block !important;
  color: var(--okx-box-wrapper-color) !important;
}

.web .drawer-menu ion-header ion-toolbar {
  position: relative;
}

.drawer-menu-wraper {
  background-image: var(--okx-menu-background-image);
  background-color: var(--okx-menu-background);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: auto;
}

.web .drawer-menu .sidebar-menu-content {
  --ion-color-base: transparent;
}

.web .drawer-menu .sidebar-menu-content:first-of-type {
  --padding-start: 0px;
}

.web .drawer-menu .sidebar-menu-content:first-of-type ion-item.okx-nav-selected ion-icon {
  background-color: var(--okx-menu-icon-color);
  stroke: var(--okx-menu-background);
  border-radius: 50%;
}

.web
  .drawer-menu
  .sidebar-menu-content:first-of-type
  ion-item.nav-item.small-text.okx-nav-selected:after {
  width: 22px;
  left: 29px;
  top: 0px;
  bottom: 0px;
}
.sidebar-menu-content {
  overflow: auto;
  padding: 5px var(--ion-padding) var(--ion-padding);
  background-color: var(--okx-menu-background);
  flex: 1;
}
.drawer-menu ion-content:first-of-type ion-item.nav-item.small-text ion-icon {
  display: none;
}
.drawer-menu ion-content:first-of-type ion-item.nav-item.small-text {
  padding-left: 6px;
}

.drawer-menu-order-button {
  margin: 0;
  width: 100%;
}

.drawer-menu .nav-icon {
  height: 18px;
  width: 18px;
  margin: 0 5px 0px 0;
  padding: 5px;
}
.web .drawer-menu .nav-title {
  margin: 15px 0 8px 0;
}
.drawer-menu .nav-title {
  display: block;
  margin: 15px 0 5px 5px;
  color: var(--okx-menu-title-color) !important;
  font-size: var(--okx-subtitle-font-size);
  font-family: var(--ion-font-family-bold) !important;
}
.web .small-drawer-header {
  padding: 15px 0 0 0;
}

.web .small-drawer-header ion-buttons {
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.web .small-drawer-header ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
}

.web .small-drawer-header ion-button ion-icon {
  font-size: 1.8rem;
  color: var(--okx-color-black);
}

.web .collapse-drawer-icon {
  display: block;
  position: absolute;
  top: 50%;
  right: var(--ion-padding);
  transform: translate(0%, -50%);
  cursor: pointer;
  font-size: 25px;
  color: var(--okx-box-wrapper-color);
}

.drawer-menu-order-button {
  --background: var(--okx-menu-button-background);
  color: var(--okx-menu-button-text);
}

.icon-placeholder {
  z-index: 5;
}

.drawer-footer {
  bottom: 0;
  padding: 0 var(--ion-padding);
}

ion-accordion:not(:first-of-type) {
  margin-top: calc(var(--ion-padding) / 2);
}
ion-accordion {
  border-radius: 10px;
  padding: 0 var(--ion-padding);
  cursor: pointer;
  background-color: var(--okx-menu-background-overlay);
}
ion-accordion ion-item[slot='header'],
.sidebar-home ion-item {
  --min-height: var(--okx-title-font-size);
}
ion-accordion ion-item[slot='header'] ion-icon {
  height: 100%;
  margin: 0;
}

ion-accordion > div ion-item {
  padding-bottom: var(--ion-padding);
}
ion-accordion ion-item[slot='header'] {
  padding: var(--ion-padding) 0;
}
ion-accordion ion-item[slot='header'] img,
.sidebar-home img {
  width: calc(var(--okx-title-font-size) * 1.2);
  height: calc(var(--okx-title-font-size) * 1.2);
  margin-right: calc(var(--ion-padding) / 2);
}
.drawer-menu .title {
  margin: 0 !important;
  line-height: 1.2;
  font-size: var(--okx-subtitle-font-size);
}

.sidebar-home {
  padding: var(--ion-padding);
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--okx-menu-background-overlay);
  margin-bottom: calc(var(--ion-padding) / 2);
  border-radius: 10px;
  margin-bottom: calc(var(--ion-padding) / 2);
}
.drawer-footer {
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.logo-wrapper {
  display: flex;
}
.logo-wrapper img {
  width: 86px;
}

.nav-logo {
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: var(--ion-padding);
  max-width: 50%;
}
