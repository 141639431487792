.points-redeem-box-wrapper > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.points-redeem-box-wrapper > div:first-of-type {
	margin-bottom: 10px;
}

.points-redeem-box-wrapper .incrementer {
	flex: 0 0 40px;
	padding: 5px;
	border-radius: 25px;
	border: 1px solid var(--okx-box-wrapper-border);
}
.points-redeem-box-wrapper .link {
	margin: 0;
}
.points-redeem-box-wrapper .incrementer-quantity-value {
	margin: 0;
}
