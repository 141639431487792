/* Unlock rewards style */

:root {
	--reward-red: #6e2b13;
	--reward-black: #000;
}

.reward {
	position: relative;
	margin-bottom: 20px;
}

.reward.green .reward-content {
	background-color: var(--ion-color-primary);
}

.reward.red .reward-content {
	background-color: var(--reward-red);
}

.reward.black .reward-content {
	background-color: var(--reward-black);
}

.reward-content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.reward-cover {
	position: absolute;
	display: grid;
	place-items: center;
	background: rgba(0, 0, 0, 0.6);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
}

.reward-cover ion-icon {
	position: absolute;
	fill: #fff;
	font-size: 42px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.reward-content::before,
.reward-content::after {
	display: block;
	content: '';
	position: absolute;
	top: 8px;
	bottom: 0;
	width: 20px;
	background: radial-gradient(#fff 0px, #fff 6px, transparent 7px, transparent);
	background-size: 20px 20px;
}

.reward-content::before {
	left: -9px;
}

.reward-content::after {
	right: -9px;
}

.reward-content div {
	color: #fff;
}

.reward-spacer {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 20px;
	min-width: 20px;
}

.reward-info,
.reward-stamp {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	text-align: center;
}

.reward-stamp {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 85px;
	min-height: 112px;
	min-width: 85px;
	position: relative;
}

.reward-stamp::after {
	display: block;
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	width: 70px;
	height: 70px;
	margin: -38px 0 0 -38px;
	border-radius: 50%;
	border: 3px #fff solid;
	background: transparent url(../../assets/images/social-icon.svg) no-repeat 50% 50%;
	background-size: 40px 40px;
}

.reward:not(.default-font) .reward-title,
.reward:not(.default-font) .reward-subtitle {
	font-family: primary-regular;
}

.reward-title,
.reward-subtitle {
	text-transform: uppercase;
}

.reward-title {
	font-size: 32px;
	font-weight: bold;
}

.reward-subtitle {
	font-size: 12px;
}

.reward-description {
	font-size: 8px;
}

.reward.default-font .reward-title {
	line-height: 28px;
	font-weight: normal;
}

.reward.default-font .reward-subtitle {
	font-weight: bold;
}

.reward-bar {
	height: 41px;
	display: flex;
	align-items: center;
	border-bottom-left-radius: 1px;
	border-bottom-right-radius: 1px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	background: var(--ion-color-white);
	margin-top: -4px;
	overflow: hidden;
}

.reward-bar > div:first-child,
.reward-bar > div:last-child {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

.reward-bar > div:first-child {
	padding-left: 16px;
}

.reward-bar > div:last-child {
	text-align: right;
	padding-right: 12px;
}

.reward-bar ion-button.button-outline {
	/* height: 25px; */
	padding: 10px 5px;
	margin-bottom: 0;
	--border-width: 1px;
	--border-color: rgba(var(--ion-color-gray-rgb), 0.5);
	--border-radius: 3px;
	color: var(--ion-color-secondary);
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}

.reward-bar p {
	color: #000;
	font-family: primary-bold;
	font-size: 16px;
	text-align: initial;
	text-transform: uppercase;
	margin: 0;
}

.web ion-modal.modal-classic.reward-modal {
	--border-radius: var(--okx-box-radius);
}
ion-modal.modal-classic.reward-modal {
	--width: 100%;
	--height: 100%;
	--border-radius: 0;
}
ion-modal.modal-classic.reward-modal .modal-classic-wrapper {
	padding: 0;
}

ion-modal.modal-classic.reward-modal .modal-classic-header {
	padding-left: 20px;
}

ion-modal.modal-classic.reward-modal .modal-classic-header h3 {
	color: var(--ion-color-primary);
	font-family: primary-bold;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
}
ion-modal .modal-classic-closer {
	color: var(--okx-box-wrapper-color);
	font-size: 18px;
	cursor: pointer;
}
ion-modal.modal-classic.reward-modal .modal-classic-closer {
	color: var(--okx-box-wrapper-color);
	font-size: 18px;
}

ion-modal.modal-classic.reward-modal .modal-classic-content {
	margin: var(--ion-padding) 0;
	padding: 0 var(--ion-padding);
}

.reward-modal-content {
	padding: 0 30px;
}

.modal-classic-content > div > img,
.reward img {
	object-fit: cover;
	object-position: center;
}

.route-loyalty > .ion-padding {
	position: static !important;
}

ion-content.route-loyalty > .ion-padding .absolute-content .big-number {
	font-weight: 600;
	font-size: 5em;
	color: var(--ion-color-primary);
}

#main > ion-content.route-rewards > .ion-padding .absolute-content ion-item {
	--border-color: var(--ion-color-light);
}

#main > ion-content.route-rewards > .ion-padding .absolute-content .inline-input > ion-text {
	flex: 0 0 25%;
}

.reward-modal-qr {
	/* background: transparent url(../../assets/images/qr-dummy.png) no-repeat 50% 0; */
	background-size: contain;
	width: 100%;
	height: 200px;
	border: 10px solid var(--ion-color-white);
}

.reward-item ion-item ion-input {
	--placeholder-color: var(--ion-color-primary);
	--placeholder-opacity: 1;
	text-transform: initial;
	margin-inline-start: 20px;
}

.web .static.route-rewards {
	background: transparent none;
}

.web ion-content.route-rewards {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content {
	border-radius: inherit;
	overflow: hidden;
	padding: 0;
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content .flex-min:first-of-type {
	padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content > .scrollable-y {
	padding: 0 var(--ion-padding);
}

.web .clickup {
	position: absolute;
}

.unlock-rewards-modal .subtitle,
.unlock-rewards-modal .normal-text {
	text-align: start;
	display: block;
}

.loyalty-reward-name {
	z-index: 2;
}
