.web .route-order + ion-toolbar.dynamic-header {
	--ion-toolbar-background: var(--ion-color-secondary);
}

.web #main > ion-content.route-order > .no-padding {
	height: 100%;
	overflow-y: auto;

	/* margin-top: 50px; */
}
#main > ion-content.route-order > .no-padding {
	background-color: var(--okx-background-color);
}
.web .order-summary-sidebar {
	top: 80px;
}

.web .order-list {
	/* position: relative;
		 left: 0;
		 right: var(--okx-sidebar-width);
		 bottom: 0;
		display: flex;
		display: block;
		flex-direction: column;
		width: calc(100% - var(--okx-sidebar-width));
		 overflow: auto;
		top: 50px;
		padding-top: 50px;
		z-index: 10; */
	width: calc(100% - var(--okx-sidebar-width));
}

.web .order-list.order-list-full {
	width: 100%;
}

.web .web-only.order-list-heading {
	position: relative;
	margin-bottom: 50px;
}
.restaurant-info-banner {
	width: 350px;
	background: var(--ion-color-white);
	height: 250px;
	border-radius: var(--okx-box-radius);
	position: absolute;
	top: 50px;
	right: 50px;
	z-index: 5;
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.restaurant-info-banner * {
	color: var(--ion-color-dark);
}
.table-icon {
	background-image: url('../../assets/images/table-icon.svg');
}
.delivery-icon {
	background-image: url('../../assets/images/delivery-icon.svg');
}
.collection-icon {
	background-image: url('../../assets/images/collection-icon.svg');
}
.restaurant-info-delivery-options {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
.restaurant-info-delivery-options .icons-wrapper {
	display: flex;
}
.restaurant-info-delivery-options .icons-wrapper svg {
	stroke: var(--ion-color-dark);
}
.restaurant-info-delivery-options .icons-wrapper > div {
	height: 40px;
	margin: 0 5px;
	width: 40px;
	object-fit: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	background-position: center;
}
.restaurant-info-delivery-options .icons-wrapper > div:last-child {
	margin-right: 0;
}
.web .web-only.order-list-heading > .order-list-restaurant-info,
.web .web-only.order-list-heading > .order-list-restaurant-image {
	display: flex;
	flex-flow: column;
	position: relative;
}
.web .web-only.order-list-heading > .order-list-restaurant-info {
	padding: 32px 50px;
}
.web .order-list-info-box,
.web .order-list-info-box .info-box-list {
	display: flex;
}
.client-logo {
	height: 50px;
	width: 70%;
	object-fit: contain;
	margin-bottom: 20px;
}
.web .order-list-info-box {
	align-items: center;
	justify-content: flex-start;
}

.web .order-list-info-box > ion-icon {
	font-size: 30px;
}

.web .order-list-info-box .info-box-list {
	flex-flow: column;
}

.web .order-list-info-box .info-box-list > ion-list {
	padding: 0;
	display: flex;
}

.web .order-list-info-box .info-box-list > ion-list > ion-button {
	height: initial;
	margin: 0 5px 0 0;
}

.web .order-list-info-box .info-box-list > ion-list > ion-button:hover {
	background: var(--ion-color-white);
}

.web .order-list-restaurant-bg {
	background-size: cover;
	background-repeat: no-repeat;
	flex: 0 1 250px;
	position: relative;
}
.web .order-list-restaurant-bg:before {
	content: '';
	background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 1) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.web .order-summary-sidebar,
.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
	left: calc(100% - var(--okx-sidebar-width));
}

.web .order-summary-sidebar {
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;
}

.web .order-summary-sidebar .add-items-row {
	display: none;
}

.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
	content: '';
	position: fixed;
	top: -200px;
	bottom: 0;
}

.web .order-summary-sidebar::before {
	width: 20px;
	box-shadow: -13px 0px 13px rgba(0, 0, 0, 0.12);
	z-index: 5;
}

.web .order-summary-sidebar::after {
	right: 0;
	background-color: #fff;
}

.web .order-summary-sidebar::after {
	width: 50px;
	background-color: var(--okx-background-color);
}
.web .order-summary-sidebar .absolute-content {
	top: 0;
	z-index: 5;
	background-color: var(--okx-background-color);
	padding: 0 !important;
}
.web .order-summary-sidebar .absolute-content .scrollable-y {
	padding: var(--ion-padding) var(--ion-padding);
}

.web .order-summary-sidebar .checkout div.righted:first-of-type {
	visibility: hidden;
}

.web .order-summary-sidebar .cnc-ordering-for {
	display: inline-block;
	width: 90%;
}

.web .order-categories {
	padding: 10px 10px 0 50px;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 3;
	background-color: var(--okx-background-color);
}

.web .order-categories h2 {
	padding: 0;
	font-size: var(--okx-title-font-size);
}

.web .order-categories-inner {
	flex-wrap: wrap;
}

.web .order-categories-inner ion-button {
	text-transform: none;
	margin-right: 5px;
}

.web .order-content {
	padding: 0 10px var(--ion-padding) 50px;
	overflow: unset;
}

.web .order-list-items {
	overflow: unset;
	height: 100%;
}

.web .order-sublist-header {
	top: 90px;
}

.order-sublist-header.empty {
	/* max-height: 0;
	height: 0px;
	overflow: hidden;
	min-height: 0; */
	display: none;
}

/* grid */

.web .order-list-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	justify-items: stretch;
	align-items: stretch;
	row-gap: 15px;
	column-gap: 15px;
	padding-right: 10px;
}

.web .order-list-grid.wrapper-grid {
	grid-template-columns: 1fr;
}

.web .order-list-grid .order-list-item {
	display: flex;
	flex-direction: column;
	width: auto;
	margin-bottom: 0;
	min-width: 0;
	background-color: var(--okx-box-wrapper-background);
	padding: 10px;
	border: 1px solid #eee;
	cursor: pointer;
	border-radius: var(--okx-box-radius);
}

.web .order-list-grid .order-list-item .item-image {
	width: 100%;
	padding-top: 100%;
	background-color: var(--ion-color-gray);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-width: 0;
	min-height: 0;
	margin: 0;
	display: block;
	height: auto;
	border-radius: 4px;
}

.web .order-list-grid .order-list-item .item-bar {
	margin: 5px 0 0 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.web .route-order .item-bar .item-name,
.web .route-order .item-bar .item-price {
	font-size: var(--okx-product-font-size);
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	color: var(--okx-box-wrapper-color);
}
.web .route-order .item-bar .small-text {
	color: var(--okx-box-wrapper-color);
}
.web .route-order .item-bar .item-name.ellipsis {
	white-space: normal;
	text-overflow: unset;
}

.web .route-order .item-bar .item-price {
	text-align: right;
	align-self: flex-end;
	flex: 0 1 35%;
}

.web .route-order .item-bar .item-price > span:first-child {
	font-size: inherit;
	font-weight: normal;
}

.web .route-order .item-bar .item-price > span.original-price {
	text-decoration: line-through;
	font-size: calc(var(--okx-small-text-font-size) - 1px);
}

.web ion-modal.modal-classic {
	--height: var(--okx-modal-height);
	min-height: 400px;
}

.web ion-split-pane.blur {
	/* filter: blur(10px); */
}

.web ion-modal.modal-classic .modal-classic-wrapper,
.web ion-modal.modal-classic .modal-classic-content {
	height: 100%;
}

.web ion-modal.modal-classic .modal-classic-content {
	display: flex;
	flex-direction: column;
	/*overflow: auto;*/
}

.web ion-modal.modal-classic .item-details-image {
	position: absolute;
	left: -12px;
	width: 130px;
	padding-top: 240px;
	height: auto;
	background-size: 100% auto;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	z-index: 3;
}

.web ion-modal.modal-classic .item-details-content {
	height: calc(100% - 90px);
	overflow-y: auto;
	padding: 0;
}
.web .item-details-modal-info > div,
.web .item-details-card-content {
	padding: 0 var(--ion-padding);
}
.web .item-details-card-content {
	padding-bottom: 70px;
}
.web ion-modal.modal-classic .item-details-card {
	height: 100%;
	position: relative;
}

.web ion-modal.modal-classic .item-details-card-price {
	text-align: left;
}

.web ion-modal.modal-classic .item-details-actions {
	position: absolute;
	left: -25px;
	bottom: -17px;
	right: -25px;
	margin-left: 0;
	padding: var(--ion-padding);
	z-index: 5;
	-webkit-transform: translate3d(0, 0, 0);
}

.web ion-modal.modal-classic .item-details-card-content .item-details-card-list {
	padding: 0;
}

.web ion-modal.modal-classic.allergen-modal,
.web ion-modal.modal-classic.apply-points-modal {
	--height: var(--okx-modal-height);
}

.web ion-modal.modal-classic.allergen-modal .modal-classic-wrapper,
.web ion-modal.modal-classic.apply-points-modal .modal-classic-wrapper {
	left: calc(100% - var(--okx-sidebar-width));
}

.web ion-modal.modal-classic.allergen-modal .title.centered {
	text-align: left;
}

.web ion-modal.modal-classic.allergen-modal .sectiontitle {
	margin: 0;
	font-weight: bold;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
	--color: var(--okx-color-black);
}

.web .order-sublist-holder {
	margin-bottom: 30px;
	grid-column: 1 / -1;
}

.web .order-list-items > .list-md,
.web .order-list-items > .list-ios {
	position: relative;
}
.restaurant-info {
	padding: 32px;
}
.web .item-details-modal .item-details-actions {
	left: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	background-color: var(--okx-box-wrapper-background);
}
.item-details-modal .modal-classic-wrapper,
.item-details-modal .modal-classic-content {
	padding: 0 !important;
}
.item-details-model-header {
	padding: var(--ion-padding);
	left: 0;
	background: var(--okx-box-wrapper-background);
	z-index: 1;
	top: 0;
	position: absolute;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	width: 100%;
	transform: translateY(-100%);
	transition: transform 0.3s ease-in-out;
}
.item-details-model-header.active {
	transform: translateY(0);
}

.item-details-modal-info img {
	height: 200px;
	width: 100%;
	object-fit: cover;
}
ion-modal.modal-classic.item-details-modal .modal-classic-closer {
	background: var(--ion-color-white);
	right: 15px;
	top: 10px;
	border-radius: 2px;
	height: 35px;
	width: 35px;
	z-index: 5;
	box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
	display: flex;
	align-items: center;
	justify-content: center;
}
ion-modal.modal-classic.item-details-modal .modal-classic-closer ion-icon {
	color: var(--ion-color-dark);
}
@media screen and (max-width: 1280px) {
	.web .order-list-grid {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 860px) {
	.web .order-list-grid {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 640px) {
	.web .order-list-grid {
		grid-template-columns: 1fr 1fr;
	}
}
