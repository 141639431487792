.order-status-details-line {
	display: grid;
	grid-template-columns: 30px auto;
	align-items: center;
	gap: 10px;
}
.order-status-details {
	display: grid;
	gap: 10px;
	position: relative;
}
.order-status-details::before {
	position: absolute;
	content: '';
	height: 100%;
	width: 2px;
	background-color: var(--ion-color-primary);
	left: 13px;
	top: 0;
}
.order-status-details-line > div:first-of-type {
	display: flex;
	align-items: center;
}
.order-status-success,
.order-status-failed {
	height: 18px;
	width: 18px;
	border-radius: 50%;
	padding: 2px;
	border: 4px solid var(--okx-background-color);
	box-sizing: border-box;
}
.order-status-failed {
	background-color: var(--ion-color-danger);
}
.order-status-success {
	background-color: var(--ion-color-success);
}
