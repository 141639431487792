.web ion-content.route-table-overview {
	width: 100%;
	height: calc(100vh - 79px);
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;
}

.route-table-overview ion-grid {
	margin-bottom: 0;
}
ion-modal.set-pay-method-modal.modal-classic {
	--height: 350px !important;
	--width:70% !important;
	--border-radius:var(--okx-box-radius)
}
ion-modal.set-pay-method-modal.small-modal.modal-classic {
	--height: 220px !important;
}
ion-modal.set-pay-method-modal.small-modal.modal-classic .modal-classic-content {
	justify-content: space-between;
	text-align: center;
	display: flex;
	flex-direction: column;
}
ion-modal.set-pay-method-modal.modal-classic .box-wrapper {
	width: 100%;
	margin: 0;
}
.bill-pay-title-wrapper {
	display: flex;
	align-items: center;
}
.bill-pay-title-wrapper .normal-text {
	margin-right: 15px;
}
.bill-pay-title-wrapper ion-button {
	margin: 0 !important;
}

.route-table-overview .checkout-btn {
	margin: 0 !important;
}
.web ion-modal.set-pay-method-modal.small-modal.modal-classic{
	--width:350px !important
}