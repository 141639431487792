.web ion-content.route-bill-completed {
	width: 100%;
	height: calc(100vh - 79px);
	
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;
}
.route-bill-completed .absolute-content img {
	height: 75px;
	margin-bottom: 10px;
}
.bill-pay-status-wrapper img,
.bill-pay-status-wrapper ion-icon {
	height: 75px;
	width: 75px;
	padding: 0;
	margin: 0 auto 10px;
}
.route-bill-completed ion-grid {
	margin-bottom: 0;
}
.payment-confirmation-wrapper {
	display: flex;
	align-items: center;
	padding-top: 5px;
}
.payment-confirmation-wrapper img {
	height: 20px !important;
	width: 20px;
	margin-right: 10px;
	margin-bottom: 0 !important;
}
