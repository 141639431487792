.text-box {
	display: flex;
	justify-content: center;
	height: 40px;
	/* background: green; */
	align-items: center;
	font-weight: bolder;
}

.box-content .order-paid-status {
	padding: 2px 8px;
	background-color: var(--ion-color-secondary);
	color: var(--ion-color-secondary-contrast) !important;
	border-radius: 15px;
	width: max-content;
	margin-bottom: 5px;
	font-size: var(--okx-small-text-font-size);
}


.card-icon-wrapper {
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	background-color:  #F5F5F5;
	;
}
.card-icon-wrapper ion-icon{
	width: 20px;
	height: 20px;
	color:  #5F6368 !important;
}