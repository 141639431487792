.service-charge-incrementer-wrapper {
	padding: var(--ion-padding) 0;
	margin: var(--ion-padding) 0;
	background-color: var(--okx-bacground-color);
}
.service-charge-incrementer-wrapper p {
	text-align: center;
}
.service-charge-incrementer-wrapper .incrementer-note {
	font-size: 25px;
	font-weight: bold;
	color: var(--ion-color-secondary);
}
.incrementer-buttons {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	padding: 0 var(--ion-padding);
	position: absolute;
	width: 100%;
}

.service-charge.absolute-content {
	padding: var(--ion-padding) 0;
	background-color: var(--okx-background-color);
}
.service-charge .incrementer {
	padding: 0 var(--ion-padding);
}
.service-charge-content .incrementer-quantity-value {
	color: var(--ion-color-secondary) !important;
}
.service-charge-incrementer-wrapper .incrementer-quantity {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
}
.service-charge-incrementer-wrapper .incrementer-quantity * {
	font-size: 25px !important;
	margin: 0 !important;
}
.service-charge .incrementer ion-button {
	height: 44px;
	width: 44px;
	margin: 10px;
	--border-color: transparent;
	--border-style: solid;
}
.service-charge .title,
.service-charge .normal-text,
.service-charge-buttons {
	margin: 0 var(--ion-padding);
	display: block;
}

.service-charge-item {
	pointer-events: none;
}
.route-order .service-charge-item,
.route-order-summary .service-charge-item {
	cursor: pointer;
	pointer-events: all;
}
ion-modal.modal-classic.service-charge-modal,
ion-modal.modal-classics.service-charge-box-modal {
	--height: 65% !important;
	--width: 80%;
	--border-radius:var(--okx-box-radius)
}
.web ion-modal.modal-classic.service-charge-modal,
.web ion-modal.modal-classic.service-charge-box-modal {
	--height: 250px !important;
	--width: var(--okx-bsmodal-width-lg);
}
.web ion-modal.modal-classic.service-charge-modal.service-charge-modal-full {
	--height: var(--okx-modal-height) !important;
}
.service-charge-box-wrapper .normal-text {
	color: var(--okx-box-wrapper-color);
}
