:root {
  --clickup-initial-height: 50px;
  /* --clickup-open-height: 50%; */
}

.pill {
  background-color: var(--okx-pill-background);
  color: var(--okx-pill-color) !important;
  padding: 0 8px;
  display: inline-block !important;
  text-transform: capitalize !important;
  font-size: var(--okx-small-text-font-size) !important;
  line-height: 24px;
  border-radius: 4px;
  margin-top: 4px;
}

.pill-action {
  cursor: pointer;
}
.pill-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
