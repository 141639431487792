.order-content-wrapper ion-row {
	color: var(--ion-color-primary);
	height: 54px;
	display: flex;
	align-items: center;
}
.order-content-wrapper ion-row .righted{
	align-self: center;
}
.order-content-wrapper .basket-item-wrapper + ion-row > ion-col {
	font-weight: normal !important;
}

.order-content-wrapper .basket-item-wrapper + ion-row > ion-col.bold {
	font-weight: bold !important;
}
.order-content-wrapper .basket-item-wrapper .sub-item {
	padding-left: 32px;
	border-top: 0 !important;
}
.order-content-wrapper .basket-item-wrapper:last-of-type {
	border-bottom-width: 0;
}
.order-content-wrapper .basket-item-wrapper-0 ion-row:first-child {
	border-top: 0;
}
.web .order-summary-sidebar .order-content-wrapper .basket-item-wrapper:last-of-type {
	border-bottom-width: 0px;
}
.order-content-wrapper ion-row {
	border-top: 1px solid var(--okx-box-wrapper-border);
	padding: calc(var(--ion-padding) / 2) var(--ion-padding)
}
.order-content-wrapper ion-row:first-child {
	border-top: none;
}
.order-content-wrapper.box-wrapper ion-row:first-child {
	border-top: none;
}
.subtotal-bordered-top {
	border-top: 2px solid var(--okx-border-color);
	border-bottom: 2px solid var(--okx-border-color);
}

.total-bordered-bottom {
	border-bottom: 2px solid var(--okx-color-black);
}

.total-bordered-top {
	border-top: 2px solid var(--okx-border-color);
	border-bottom: 2px solid var(--okx-border-color);
}

ion-col.grow {
	flex-grow: 3.5;
}

.add-items-btn {
	font-weight: bold !important;
	margin: 10px 0;
}
.web .order-summary-sidebar .add-items-btn {
	display: none;
}
/* .web .add-items-btn {
	display: none;
} */

.service-charge-values {
	display: flex;
	padding: calc(var(--ion-padding) / 2) !important;
	gap:10px;
}
ion-modal .decline-service-charge-btn {
	display: none;
}
.service-charge-box-wrapper ion-button {
	flex: 1;
	margin: 0 2px;
	--color: var(--okx-box-wrapper-color);
	--background: transparent !important;
	--border-radius:8px;
	--border-width:1px;
}
.service-charge-values ion-button.is-active {
	--ion-color-base: var(--ion-color-primary) !important;
	--ion-color-contrast: var(--ion-color-primary-contrast) !important;
}
.service-charge-values ion-button .normal-text{
	
	color: var(--ion-color-primary) !important;
}
.service-charge-values ion-button.is-active .normal-text{
	
	color: var(--ion-color-primary-contrast) !important;
}
.service-charge-values ion-button {
	--ion-color-base: transparent !important;
	--ion-color-contrast: var(--ion-color-primary) !important;
	height: 40px;
}
.service-charge-values ion-button * {
	pointer-events: none !important;
}
.service-charge-box-wrapper {
	width: 100%;
	padding: 0 !important;
	background-color: var(--okx-box-wrapper-background);
	border-radius: var(--okx-box-radius);
	padding: var(--ion-padding) 0 !important;
	gap: 10px;
}
.service-charge-box-wrapper > div {
	display: flex;
	justify-content: space-between;
	padding:0  var(--ion-padding) var(--ion-padding);
	width: 100%;
}
.service-charge-box-wrapper > div:first-of-type{
	border-bottom: 1px solid var(--okx-box-wrapper-border);
}

.service-charge-box-modal .modal-wrapper {
	border-radius: 0;
}
.web .service-charge-box-modal .modal-wrapper {
	border-radius: var(--okx-box-radius);
}
.decline-service-charge-btn.link.underlined {
	font-size: calc(var(--ion-font-size) - 2px) !important;
}
.basket-items-wrapper.unclickable * {
	pointer-events: none !important;
}
.basket-items-wrapper {
	position: relative;
}
.basket-items-wrapper > div {
	margin-bottom: 15px;
	display: flex;
}
.basket-items-wrapper .order-item-image {
	height: 100px;
	width: 100px;
	border-radius: var(--okx-box-radius);
	background-size: contain;
	margin-right: 10px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: var(--ion-color-gray);
	position: relative;
	overflow: hidden;
}

.basket-items-wrapper .order-item-image::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.basket-items-wrapper .item-content-wrapper {
	flex: 1;
}
.basket-items-wrapper .item-content-actions {
	margin-top: 20px;
}
.basket-items-wrapper .item-content-actions .incrementer ion-button ion-icon {
	color: var(--ion-color-primary-contrast);
}
.remove-apply-voucher,
.web ion-modal.modal-classic.remove-apply-voucher {
	--height: 200px !important;
	--width:280px !important;
	--border-radius:var(--okx-box-radius) !important

}
.remove-apply-voucher .modal-classic-action{
	padding: var(--ion-padding);
	margin: 0;

}
.remove-apply-voucher .modal-classic-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.order-summary-items-wrapper {
	margin-right: 100px;
}

.web .mutli-basket-items-wrapper .add-items-btn {
	display: flex;
}

.restautant-order-status {
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 3px;
	border-radius: 10px;
}
.restautant-order-status .normal-text {
	color: var(--ion-color-white);
}
.restautant-order-status.restautant-order-status-gray .normal-text {
	color: var(--ion-color-primary);
}
.restautant-order-status-success {
	background-color: var(--ion-color-success);
}
.restautant-order-status-danger {
	background-color: var(--ion-color-danger);
}
.restautant-order-status-gray {
	background-color: var(--ion-color-gray);
}
.restautant-order-status-warning {
	background-color: var(--ion-color-warning);
}

.restautant-order-status-success * {
	color: var(--ion-color-success-contrast) !important;
}
.restautant-order-status-danger * {
	color: var(--ion-color-danger-contrast) !important;
}
.restautant-order-status-gray * {
	color: var(--ion-color-primary) !important;
}
.restautant-order-status-warning * {
	color: var(--ion-color-warning-contrast) !important;
}

.basket-items-wrapper-gray {
	border-bottom: 5px solid var(--ion-color-gray);
}
.basket-items-wrapper-danger {
	border-bottom: 5px solid var(--ion-color-danger);
}
.basket-items-wrapper-success {
	border-bottom: 5px solid var(--ion-color-success);
}
.basket-items-wrapper-warning {
	border-bottom: 5px solid var(--ion-color-warning);
}

div[class*='basket-items-wrapper-'] > .box-content:last-of-type {
	margin-bottom: 0;
}
