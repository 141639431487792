ion-content.route-history-details > .ion-padding > .absolute-content {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}

ion-content.route-history-details > .ion-padding > .absolute-content > .scrollable-y {
	height: 100%;
	padding-left: var(--ion-padding, 16px);
	padding-right: var(--ion-padding, 16px);
	padding-bottom: var(--ion-padding, 16px);
	padding-top: var(--ion-padding, 16px);

}

.history-details {
	z-index: 1;
}

.history-details ion-grid {
	padding-inline-start: 0;
	padding-inline-end: 0;
}

.history-details ion-row:last-child {
	border-bottom: 0 none;
}

.history-details .no-border {
	border-bottom: 0 none;
}

.history-details .okx-box-footer {
	border-top: 2px solid var(--okx-border-color);
	padding-left: 0;
	margin-left: 0;
	padding-right: 0;
	margin-right: 0;
}

.history-details .okx-box-footer ion-row {
	border-bottom: 0 none;
}

.history-details .okx-box,
.history-details .okx-box-header {
	border-color: var(--ion-color-secondary);
	border-width: 0px;
}

.web #main > ion-content.route-history-details > .ion-padding > .absolute-content.history-details {
	background: none;
	padding: 0;
}

.web #main > ion-content.route-history-details > .ion-padding > .absolute-content.history-details:after {
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web #main > ion-content.route-history-details > .ion-padding > .absolute-content.history-details > div.scrollable-y {
	height: 100%;
}

.web .history-details .okx-box,
.web .history-details .okx-box-header {
	border-color: var(--okx-border-color);
	border-width: 0px;
}

.history-details .okx-box-header > p br {
	display: none;
}

.history-details .okx-box-header > p {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 7px 0;
	align-items: center;
}

.web .history-details .okx-box-header > p {
	color: var(--ion-color-dark);
	display: grid;
	grid-template-columns: repeat(2, minmax(200px, auto));
	grid-gap: 7px 0;
	align-items: center;
}

.history-details .okx-box-header > p ion-text:nth-of-type(odd) strong {
	font-weight: normal;
}

.history-details .okx-box-header > p ion-text:nth-of-type(even) {
	text-align: right;
	font-weight: bold;
}

.orderPlacedCol {
	flex: 0.5 !important;
}

.route-history-details .header-title .title::after{
	content: '' !important;
}