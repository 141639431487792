.toggle {
	display: flex;
	flex-direction: column;
}

.web #main > ion-content.route-register {
	z-index: 5;
}
.route-create-account .absolute-content {
	padding: var(--ion-padding);
	background-color: var(--okx-background-color);
	text-align: start;
}
