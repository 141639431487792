.dashboard-bars-wrapper {
  top: 80px;
  z-index: 5;
  width: 100%;
  padding-top: var(--ion-padding);
  background-color: var(--okx-dashboard-background);
}
.dashboard-bar-wrapper {
  width: 100%;
  padding: 0 var(--ion-padding) 20px;
  background-color: var(--okx-dashboard-background);
}
.dashboard-bar {
  width: 100%;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
  padding: 5px 20px;
  height: 48px;
}
.dashboard-bar ion-icon,.dashboard-bar img,.dashboard-bar svg{
  height: 24px;
  width: 24px;
  object-fit: contain;
}
.dashboard-bar svg{
  fill:  var(--ion-color-primary);
}
.dashboard-bars-wrapper .normal-text {
  color: var(--ion-color-primary) !important;
}

.dashboard-bar ion-icon{
  color: #5F6368 !important;
}