.web ion-content.route-split-bill-by-amount {
	width: 100%;
	height: calc(100vh - 79px);
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;
}
.route-split-bill-by-amount ion-grid {
	margin-bottom: 0;
}
.custom-amount-selector-wrapper .normal-text {
	display: block;
	margin-bottom: 5px;
}
